import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, DatePicker, Radio, Select } from 'antd';
import useGetPublicData from '../../../hooks/useGetPublicData';

const { Option } = Select;

const RemoteJobApplicationForm = ({ callback = () => null }) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState([]);
    const { getPublicData } = useGetPublicData();

    async function fetchCountries() {
        await getPublicData("country", (d) => setdata(d));
    }

    useEffect(() => {
        fetchCountries();
    }, []);
    const jobSource = [
        "Facebook",
        "LinkedIn",
        "Twitter", "Instagram", "Indeed", "Others"
    ]
    function applyWarning(values) {
        const formattedValues = {
            'First Name': values.firstName,
            'Middle Name': values.middleName || "",
            'Last Name': values.lastName,
            'Email Address': values.emailAddress,
            'Gender': values.gender,
            'Home Address': values.homeAddress,
            'Phone Number': values.phoneNumber,
            'Date of Birth': values.dateOfBirth ? values.dateOfBirth.format('DD-MM-YYYY') : undefined,
            'Nationality': values.nationality,
            'Highest Level of Education': values.educationLevel,
            'Relevant Skills': values.skills,
            'Do you have prior remote job experience?': values.remoteExperience,
            'Job Role Applied For': values.jobRole,
            'Availability (Full-time/Part-time)': values.availability,
            'Preferred Working Hours': values.workingHours,
            'How did you hear about this job?': values.jobSource,
        };

        Modal.confirm({
            title: 'Warning',
            content: (
                <div>
                    <p style={{ fontSize: 14 }}>
                        Please ensure <strong>{values.emailAddress}</strong> is a valid email.
                        <p style={{ fontSize: 14 }}>If selected, the details about the interview will be sent to <strong>{values.emailAddress}</strong>.
                            Please ensure you check it regularly.</p>
                    </p>
                </div>
            ),
            okText: 'Proceed',
            cancelText: 'Cancel',
            okType: 'primary',
            okButtonProps: {
                style: { backgroundColor: '#00b074', borderColor: '#00b074' },
            },
            onOk() {
                callback(formattedValues);
            },
            onCancel() {
                console.log('Cancel clicked');
            },
        });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={applyWarning}
            size="large"
        >
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please enter your first name' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Middle Name" name="middleName">
                <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please enter your last name' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Email Address (Ensure you use a correct email)." name="emailAddress" rules={[{ required: true, message: 'Please enter your email address' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Home Address" name="homeAddress" rules={[{ required: true, message: 'Please enter your home address' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true, message: 'Please select your date of birth' }]}>
                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Please select your gender' }]}>
                <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="prefer-not-to-say">Prefer not to say</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Nationality" name="nationality" rules={[{ required: true, message: 'Please select your nationality' }]}>
                <Select>
                    {data.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Highest Level of Education" name="educationLevel" rules={[{ required: true, message: 'Please select your education level' }]}>
                <Select>
                    <Option value="high-school">High School</Option>
                    <Option value="college">College</Option>
                    <Option value="university">University</Option>
                    <Option value="other">Other</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Relevant Skills (e.g., typing speed, proficiency in MS Office, etc.)" name="skills" rules={[{ required: true, message: 'Please list your relevant skills' }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="Do you have prior remote job experience?" name="remoteExperience" rules={[{ required: true, message: 'Please select your remote job experience status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Job Role Applied For" name="jobRole" rules={[{ required: true, message: 'Please enter the role you are applying for' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Availability (Full-time/Part-time)" name="availability" rules={[{ required: true, message: 'Please select your availability' }]}>
                <Radio.Group>
                    <Radio value="full-time">Full-time</Radio>
                    <Radio value="part-time">Part-time</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Preferred Working Hours" name="workingHours" rules={[{ required: true, message: 'Please enter your preferred working hours' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="How did you hear about this job?" name="jobSource" rules={[{ required: true, message: 'Please enter how you heard about this job' }]}>
                <Select>
                    {jobSource.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button className='btn-primary' htmlType="submit">Submit</Button>
            </Form.Item>
        </Form>
    );
};

export default RemoteJobApplicationForm;

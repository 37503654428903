import { notification } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
const App = createContext()
export function Context(params) {
    return useContext(App)
}
export default function AppContextProvider({ children }) {
    const [is500Error, setIs500Error] = useState(false)
    const [globalLoading, setGLobalLoading] = useState(false)
    const [feedback, setFeedback] = useState()
    const [user, setUser] = useState()
    const [app, setApp] = useState({})
    const [country, setCountry] = useState("")
    const [showFacebook, setShowFacebook] = useState(false)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, desc) => {
        api[type]({
            description: <div dangerouslySetInnerHTML={{ __html: desc }}></div>,
            closeIcon: null
        });
    };
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <App.Provider value={{ showFacebook, setShowFacebook, country, setCountry, app, setApp, user, setUser, setIs500Error, screenWidth, contextHolder, openNotificationWithIcon, is500Error, globalLoading, feedback, setFeedback, setGLobalLoading }}>{children}</App.Provider>
    )
}

import React, { useEffect, useState } from 'react';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import { Modal } from 'antd';
import usePostPrivate from '../../../hooks/usePostPrivate';
import AlertComp from '../../../components/comps/alert';
import Applicationpreview from '../../job/comp/applicationpreview';
import { excludeItems, truncateEmail } from '../../../util';
import NoDataCard from '../../../components/comps/nodata';
import Preloader from '../../../components/comps/preloader';

const AdminPurchase = () => {
    const { getData } = useGetPrivateData()
    const [data, setdata] = useState()
    const [open, setopen] = useState(false)
    const [active, setactive] = useState()
    async function fetchJobs(params) {
        await getData("purchase", (d) => { setdata(d); })
    }
    const { postData } = usePostPrivate()
    async function updateStatus(status) {
        await postData("purchase/update", { id: data[active]._id, action: status },)
        setopen(false); setactive();
    }

    useEffect(() => {
        fetchJobs()
    }, [])

    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open) {
            mod[0]?.classList.add("px-0")
        }
    }, [open])
    return (
        <>
            <Preloader open={!data} />
            <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
                <div class="mb-5 px-5">
                    <h6 className='my-3 text-center'>Purchase Candidates </h6>
                    <AlertComp />
                    {data && data?.length > 0 ? <div class="row justify-content-center card py-3 px-3">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    {data.map((item, index) =>
                                        <tr role='button' onClick={() => { setactive(index); setopen(true) }} class="border d-flex align-items-center justify-content-between border-color-2">
                                            <th scope="row" class="pl-6 border-0 py-7 pr-0">
                                                <span
                                                    class="media align-items-center "
                                                >
                                                    <h4
                                                        class="font-size-4 mb-0 font-weight-semibold text-black-2"
                                                    >
                                                        {truncateEmail(item["Email Address"])}
                                                    </h4>
                                                </span>
                                            </th>
                                            <td class="table-y-middle py-7  pr-0">
                                                <div class="d-flex justify-content-end">
                                                    <span
                                                        role='button'
                                                        class="font-size-3 font-weight-bold btn btn-unstyled text-success text-uppercase">{item["Purchase Item"]}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div> : data && <NoDataCard />}
                </div>
            </div>

            <Modal
                style={{ top: 20, }}
                open={open}
                onCancel={() => { setactive(); setopen(false); }}
                footer={() => <div className='d-flex justify-content-between px-7'>
                    <button onClick={() => updateStatus("reject")} className='btn btn-danger'>REJECT</button>
                    <button onClick={() => updateStatus("accept")} className='btn btn-success'>ACCEPT</button>
                </div>}
            >
                {data?.length > 0 && <>
                    <Applicationpreview data={excludeItems(data[active], ["_id"])} title="" />
                </>}
            </Modal>
        </>
    );
};

export default AdminPurchase;
